<template>
  <div class="main-content">
    <avue-crud
      ref="crud"
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
      @selection-change="selectionChange"
    >
      <template #header>
        <div>
          <div class="flex flex-wrap align-items marginBottom20">
            <div class="flex align-items marginRight10 marginBottom10">
              <!-- <span class="span marginRight10 width80 textAlignRight"> </span> -->
              <div class="width300">
                <el-input
                  v-model="searchData.conditions"
                  placeholder="id/学生姓名/手机号"
                  clearable
                  maxlength="50"
                ></el-input>
              </div>
            </div>
            <div class="flex align-items marginRight10 marginBottom10">
              <!-- <span class="span marginRight10 width80 textAlignRight"> </span> -->
              <div class="width300">
                <el-select
                  v-model="searchData.levelId"
                  clearable
                  placeholder="当前学生等级"
                >
                  <el-option
                    v-for="(item, index) in studentList"
                    :key="index"
                    :label="item.levelName"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <div class="flex align-items marginRight10 marginBottom10">
              <!-- <span class="span marginRight10 width80 textAlignRight"> </span> -->
              <div class="width300">
                <el-select
                  v-model="searchData.classId"
                  clearable
                  placeholder="选择班级"
                >
                  <el-option
                    v-for="(item, index) in classList"
                    :key="index"
                    :label="item.className"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <div class="flex align-items marginRight10 marginBottom10">
              <!-- <span class="span marginRight10 width80 textAlignRight"> </span> -->
              <div class="width300">
                <el-select
                  v-model="searchData.state"
                  clearable
                  placeholder="状态"
                >
                  <el-option
                    v-for="(item, index) in OStatus"
                    :key="index"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </div>
            </div>

            <el-button
              size="medium"
              type="primary"
              @click="handleSearch"
              style="margin-bottom: 10px"
              class="marginLeft10"
              >搜索</el-button
            >
            <el-button
              size="medium"
              type="info"
              @click="handleReset"
              style="margin-bottom: 10px"
              class="marginLeft10 marginRight10"
              >重置</el-button
            >
            <div class="flex align-items marginRight10 marginBottom10">
              <el-button size="medium" type="primary" @click="handleCreate"
                >新建</el-button
              >
            </div>
            <div class="flex align-items marginRight10 marginBottom10">
              <el-button
                size="medium"
                type="primary"
                @click="handleBatch('批量加入班级')"
                >批量加入班级</el-button
              >
            </div>
            <div class="flex align-items marginRight10 marginBottom10">
              <el-button
                size="medium"
                type="primary"
                @click="handleBatch('批量添加等级')"
                >批量添加等级</el-button
              >
            </div>
            <div class="flex align-items marginRight10 marginBottom10">
              <el-upload
                class="upload-demo"
                action="/api/web/student/student/importStudent"
                :show-file-list="false"
                :on-success="handleSuccess"
                :before-upload="beforeUpload"
              >
                <el-button size="medium" type="primary">导入</el-button>
              </el-upload>
            </div>
            <div class="flex align-items marginRight10 marginBottom10">
              <el-button
                size="medium"
                type="primary"
                @click="handleExport"
                :loading="showLoadingExcel"
                >导入模版</el-button
              >
            </div>
          </div>
        </div>
      </template>

      <template slot="list" slot-scope="{ row }">
        <div>
          {{
            row.list.length
              ? row.list
                  .map((item) => {
                    return item.levelName;
                  })
                  .join(";")
              : "/"
          }}
        </div>
      </template>
      <template slot="studenNum" slot-scope="{ row }">
        <el-button type="text" @click="handNum(row)">{{
          row.studenNum
        }}</el-button>
      </template>

      <template slot="headPortrait" slot-scope="{ row }">
        <div>
          <el-image
            v-if="row.headPortrait"
            style="width: 50px; height: 50px;border-radius: 50%;"
            :src="row.headPortrait"
            :preview-src-list="[row.headPortrait]"
          >
          </el-image>
        </div>
      </template>
      <template slot="state" slot-scope="{ row }">
        <div>
          {{ row.state ? "启用" : "禁用" }}
        </div>
      </template>

      <template slot="menu" slot-scope="{ row }">
        <el-button type="text" size="mini" @click="handleEdit(row)"
          >编辑</el-button
        >
        <el-button type="text" size="mini" @click="handleDelete(row)">{{
          row.state ? "禁用" : "启用"
        }}</el-button>
      </template>
    </avue-crud>
    <el-dialog
      v-if="dialogVisible"
      :title="`${form.id ? '编辑' : '新建'}`"
      :visible.sync="dialogVisible"
      width="650px"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <avue-form ref="form" v-model="form" :option="formOption">
        <template slot="levelIds">
          <el-select
            v-model="form.levelIds"
            clearable
            placeholder="选择学生等级"
            @change="getHighestClass"
            multiple
          >
            <el-option
              v-for="(item, index) in studentList"
              :key="index"
              :label="item.levelName"
              :value="item.id"
              :data="item"
            ></el-option>
          </el-select>
        </template>
        <template slot="classId">
          <el-select
            v-model="form.classId"
            clearable
            placeholder="选择学生班级"
          >
            <el-option
              v-for="(item, index) in highestClassList"
              :key="index"
              :label="item.className"
              :value="item.id"
            ></el-option>
          </el-select>
        </template>

        <template slot="headPortrait">
          <FormImgUpload
            :url="form.headPortrait"
            @upload="upload"
            :limit="1"
            :maxSize="2"
            accept=".png,.jpg"
            :multiple="true"
            :disabled="false"
          />
          <div style="color:  #FF001F">
            建议尺寸：60px*60px，图片格式：.png，.jpg
          </div>
        </template>
        <template slot="menuForm">
          <el-button @click="handleClose">取消</el-button>
          <el-button
            type="primary"
            :loading="showLoadingForm"
            @click="submit(form)"
            >确定</el-button
          >
        </template>
      </avue-form>
    </el-dialog>
    <el-dialog
      v-if="visibleTable"
      title=""
      :visible.sync="visibleTable"
      width="49%"
      :before-close="handleCloseTable"
      :close-on-click-modal="false"
    >
      <div style="display: flex;justify-content: center;margin-top: 15px;">
        <el-button size="small" @click="recert">关闭</el-button>
      </div>
    </el-dialog>

    <!-- 批量添加班级 、 等级 -->
    <el-dialog
      v-if="visibleBatch"
      :title="batchName"
      :visible.sync="visibleBatch"
      width="650px"
      :before-close="closeBatch"
      :close-on-click-modal="false"
      ><el-form
        ref="formBatch"
        :model="formBatch"
        label-position="right"
        label-width="100px"
        :rules="rules"
      >
        <el-form-item
          label="班级"
          prop="classId"
          v-if="batchName == '批量加入班级'"
        >
          <el-select v-model="formBatch.classId" clearable placeholder="">
            <el-option
              v-for="(item, index) in classList"
              :key="index"
              :label="item.className"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="等级"
          prop="levelId"
          v-if="batchName == '批量添加等级'"
        >
          <el-select v-model="formBatch.levelId" clearable placeholder="">
            <el-option
              v-for="(item, index) in studentList"
              :key="index"
              :label="item.levelName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <div style="display: flex;justify-content: center;">
          <el-button size="small" @click="closeBatch">取消</el-button>
          <el-button
            type="primary"
            :loading="loadingBatch"
            size="small"
            @click="submitBatch"
            >确定</el-button
          >
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { studentLevelList } from "@/api/user/level";
import {
  studentStudentPage,
  studentAddStudent,
  studentUpdateStudent,
  studentBatchJoinClass,
  studentBatchAddLevel,
  studentClassList,
  studentStudentExport,
  studentImportStudent,
  studentUpdateState,
} from "@/api/user/student";
import { classClassStudent } from "@/api/user/class";
import { classClassList } from "@/api/user/class";
import dayjs from "dayjs";
import qs from "qs";
import { EStatus, OStatus, MStatus } from "@/enums/beCurrent/EBeCurrent";
import FormImgUpload from "@/components/formComponents/formImgUpload";
import { mobilePhoneRule } from "@/utils/formRules";
import { exportExcel } from "@/components/exportExcel";
export default {
  name: "index",
  components: {
    FormImgUpload,
  },
  data() {
    return {
      EStatus: EStatus,
      MStatus: MStatus,
      OStatus: OStatus,
      searchData: {},
      page: {
        total: 0,
        currentPage: 1,
      },
      tableData: [],
      showLoading: false,
      showLoadingForm: false,
      dialogVisible: false,
      option: {
        title: "",
        titleSize: "h3",
        card: false,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: false, // 显示索引
        indexLabel: "序号", // 索引文字
        size: "small",
        page: true, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        editBtn: false,
        delBtn: false,
        selection: true,
        reserveSelection: true,
        selectionFixed: true,
        tip: false,
        column: [
          {
            label: "id",
            prop: "id",
          },
          {
            label: "头像",
            prop: "headPortrait",
          },
          {
            label: "学生姓名",
            prop: "studentName",
          },
          {
            label: "手机号",
            prop: "phone",
          },
          {
            label: "当前学生等级",
            prop: "levelName",
          },
          {
            label: "班级",
            prop: "className",
          },
          {
            label: "班主任",
            prop: "teacherName",
          },
          {
            label: "星星数量",
            prop: "starNum",
          },
          {
            label: "已开通的学生等级",
            prop: "list",
            width: 150,
          },
          {
            label: "创建时间",
            prop: "createTime",
          },
          {
            label: "状态",
            prop: "state",
          },
        ],
      },

      dialogVisible: false,
      form: {
        id: "",
        headPortrait: [],
      },
      formOption: {
        submitBtn: false,
        submitText: "确定",
        emptyBtn: false,
        labelWidth: "140",
        row: true,
        column: [
          {
            label: "学生姓名",
            prop: "studentName",
            maxlength: 10,
            span: 16,
            row: true,
            showWordLimit: true,
            placeholder: "输入学生姓名",
            rules: [
              {
                required: true,
                message: "请输入",
                trigger: "blur",
              },
            ],
          },
          {
            label: "手机号",
            prop: "phone",
            maxlength: 11,
            span: 16,
            row: true,
            showWordLimit: true,
            placeholder: "输入手机号",
            rules: [
              {
                required: true,
                message: "请输入",
                trigger: "blur",
              },
              ...mobilePhoneRule,
            ],
          },
          {
            label: "已开通的学生等级",
            prop: "levelIds",
            span: 16,
            row: true,
            placeholder: "",
            rules: [
              {
                required: true,
                message: "请输入",
                trigger: "blur",
              },
            ],
          },
          {
            label: "班级",
            prop: "classId",
            span: 16,
            row: true,
            placeholder: "选择班级",
            rules: [
              // {
              //   required: true,
              //   message: "请输入",
              //   trigger: "blur",
              // },
            ],
          },
          {
            label: "头像",
            prop: "headPortrait",
            type: "text",
            maxlength: 50,
            span: 24,
            rules: [
              // {
              //   required: true,
              //   message: "请上传",
              //   trigger: "blur",
              // },
              // {
              //   validator: (rule, value, callback) => {
              //     if (value.length === 0) {
              //       callback(new Error("请上传"));
              //     } else {
              //       callback();
              //     }
              //   },
              // },
            ],
          },
        ],
      },
      studentList: [], //学生等级下拉
      classList: [], //班级下拉
      highestClassList: [], //班级下拉 跟着学生等级联动
      visibleTable: false, //密码弹窗
      studenNumList: [], //学生数量数组
      showLoadingExcel: false,
      batchName: "", //批量添加弹窗名称
      visibleBatch: false, //批量弹窗
      formBatch: {},
      loadingBatch: false, //批量提交防重
      //校验规则
      rules: {
        classId: [
          {
            required: true,
            message: "请选择",
            trigger: "blur",
          },
        ],
        levelId: [
          {
            required: true,
            message: "请选择",
            trigger: "blur",
          },
        ],
      },
      selectedRowKeys: [],
    };
  },
  created() {
    this.getStudent();
    this.getClass();
  },
  methods: {
    onLoad() {
      this.showLoading = true;
      const searchData = {
        ...this.searchData,
      };
      if (this.searchData.state == 2) {
        searchData.state = false;
      }
      if (this.searchData.state == 1) {
        searchData.state = true;
      }
      studentStudentPage({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...searchData,
      }).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      });
    },

    // 查询
    handleSearch() {
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 重置
    handleReset() {
      // console.log('重置')
      this.searchData = {
        state: "",
        name: "",
        createTime: "",
      };
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 添加
    handleCreate() {
      this.form = {
        headPortrait: [],
      };
      this.dialogVisible = true;
    },
    // 关闭
    handleClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.resetForm();
      }, 500);
    },
    // 禁用
    handleDelete(row) {
      // console.log('删除',row)
      this.$confirm(`确定${row.state ? "禁用" : "启用"}吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        studentUpdateState({ id: row.id, state: !row.state }).then((res) => {
          if (res.code === 200) {
            this.$message.success("操作成功!");
            this.$refs.crud.toggleSelection();
            this.onLoad();
          }
        });
      });
    },
    //编辑
    async handleEdit(row) {
      this.form.id = row.id;
      if (row.list.length) {
        const highestLevelObject = row.list.reduce(
          (maxObject, currentObject) => {
            console.log(maxObject, currentObject);
            if (currentObject.sort > maxObject.sort) {
              return currentObject;
            } else {
              return maxObject;
            }
          },
          { sort: 0 }
        );
        console.log(highestLevelObject.id, "highestLevelObject.id");
        this.getHighest(highestLevelObject.id);
      }

      this.form = {
        ...row,
        headPortrait: row.headPortrait ? [row.headPortrait] : [],
        levelIds: row.list.length
          ? row.list.map((item) => {
              return item.id;
            })
          : [],
      };
      console.log(this.form);
      this.dialogVisible = true;
      // classClassView(row.id).then((res) => {
      //   if (res.code == 200) {

      //   }
      // });
    },

    // 提交
    submit(form) {
      this.$refs.form.$refs.form.validate((valid, done) => {
        if (valid) {
          if (this.showLoadingForm) {
            return;
          }
          this.showLoadingForm = true;
          console.log(form.levelIds, "formformform");
          // return
          if (form.id) {
            studentUpdateStudent({
              ...form,
              headPortrait: form.headPortrait.length
                ? form.headPortrait[0]
                : "",
              levelIds: form.levelIds,
            })
              .then((res) => {
                if (res.code === 200) {
                  this.$message.success("操作成功");
                  this.dialogVisible = false;
                  setTimeout(() => {
                    this.resetForm();
                  }, 500);
                  this.onLoad();
                  this.showLoadingForm = false;
                } else {
                  // this.$message.error(res.msg);
                  this.showLoadingForm = false;
                  done();
                }
              })
              .catch(() => (this.showLoadingForm = false));
          } else {
            studentAddStudent({
              ...form,
              headPortrait: form.headPortrait.length
                ? form.headPortrait[0]
                : "",
              levelIds: form.levelIds,
            })
              .then((res) => {
                if (res.code === 200) {
                  this.$message.success("操作成功");
                  this.dialogVisible = false;
                  setTimeout(() => {
                    this.resetForm();
                  }, 500);
                  this.onLoad();
                  this.showLoadingForm = false;
                } else {
                  // this.$message.error(res.msg);
                  this.showLoadingForm = false;
                  done();
                }
              })
              .catch(() => (this.showLoadingForm = false));
          }
          // this.form.type = 1;
          // done()
        }
      });
    },
    //输入
    salaryDianChange(e, t) {
      const value = e.target.value;
      this.form[t] = value;
    },
    //关闭学生数量弹窗
    recert() {
      this.visibleTable = false;
    },
    //详情
    jump(row) {
      this.$router.push({
        path: "/user/plan",
        query: {
          id: row.id,
        },
      });
    },
    //获取学生等级下拉
    getStudent() {
      studentLevelList({}).then((res) => {
        if (res.code == 200) {
          this.studentList = res.data;
        }
      });
    },
    //获取班级下拉列表
    getClass() {
      classClassList().then((res) => {
        if (res.code == 200) {
          this.classList = res.data;
        }
      });
    },

    // 打开班级学生弹窗
    handNum(row) {
      classClassStudent(row.id).then((res) => {
        if (res.code == 200) {
          this.studenNumList = res.data;
          this.visibleTable = true;
        }
      });
    },
    handleCloseTable() {
      this.visibleTable = false;
    },

    async handleExport() {
      try {
        if (this.showLoadingExcel) return;
        this.showLoadingExcel = true;
        let params = {};
        await exportExcel(
          "/web/student/student/studentExport",
          params,
          this.$store.state.user.Token
        );
        console.log("111111");
        this.showLoadingExcel = false;
      } catch (e) {
        this.showLoadingExcel = false;
      }
    },
    // 导入成功
    handleSuccess(res, file) {
      // // console.log(1111111,res, file);
      this.uploadShow = false;
      if (res.code === 200) {
        this.onLoad();
        this.$message.success("导入成功");
      } else {
        this.$message.error(res.msg);
        this.uploadShow = false;
      }
    },
    beforeUpload(file) {
      this.loading = true;
      // 文件类型
      const isType = file.type === "application/vnd.ms-excel";
      const isTypeComputer =
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      const fileType = isType || isTypeComputer;
      if (!fileType) {
        this.$message.error("上传文件只能是xls/xlsx格式！");
      } // 文件大小限制为2M
      const fileLimit = file.size / 1024 / 1024 < 20;
      if (!fileLimit) {
        this.$message.error("上传文件大小不超过20M！");
      }
      // this.loading = false
      return fileType && fileLimit;
    },

    //图片上传
    upload(e) {
      if (Array.isArray(e)) {
        this.form.headPortrait = [...e];
      } else {
        this.form.headPortrait = [...this.form.headPortrait, e];
      }
    },

    // 选择学生等级获取最高的班级列表
    async getHighestClass(e) {
      this.form.classId = "";
      const newArray = e.reduce((result, id) => {
        const matchingItem = this.studentList.find((item) => item.id === id);
        if (matchingItem) {
          result.push(matchingItem);
        }
        return result;
      }, []);
      this.highestClassList = await this.getInfoWithMaxSort(newArray);
      // const highestLevelObject = newArray.reduce(
      //   (maxObject, currentObject) => {
      //     console.log(maxObject, currentObject);
      //     if (currentObject.sort > maxObject.sort) {
      //       return currentObject;
      //     } else {
      //       return maxObject;
      //     }
      //   },
      //   { sort: 0 }
      // );

      // this.getHighest(highestLevelObject.id);
    },
    async getInfoWithMaxSort(dataArray) {
      // 对数组对象进行排序,按照 sort 属性从大到小排序
      dataArray.sort((a, b) => b.sort - a.sort);

      // 遍历排序后的数组,依次调用 getInfo 方法
      for (const item of dataArray) {
        const result = await this.getHighest(item.id);
        console.log(result, "resultresultresult");
        if (result.length > 0) {
          return result;
        }
      }

      // 如果遍历完所有元素,都没有获取到有效数据,则返回空数组
      return [];
    },
    //获取班级下拉列表 学生等级联动
    async getHighest(id) {
      let result = [];
      await studentClassList({ studentLevelId: id }).then((res) => {
        if (res.code == 200) {
          result = res.data;
          return result;
        }
      });
      return result;
    },

    //批量弹窗关闭
    closeBatch() {
      this.visibleBatch = false;
    },
    //批量确认提交
    submitBatch() {
      if (this.loadingBatch) return;
      this.loadingBatch = true;
      if (this.batchName == "批量加入班级") {
        studentBatchJoinClass({
          ...this.formBatch,
          studentLevelList: this.selectedRowKeys.map((item) => {
            return {
              list: item.list,
              studentId: item.id,
            };
          }),
        })
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("操作成功");
              this.loadingBatch = false;
              setTimeout(() => {
                this.resetForm();
              }, 500);
              this.onLoad();
              this.loadingBatch = false;
              this.visibleBatch = false;
              this.$refs.crud.toggleSelection();
            } else {
              // this.$message.error(res.msg);
              this.loadingBatch = false;
              done();
            }
          })
          .catch(() => (this.loadingBatch = false));
      } else {
        console.log(this.formBatch.levelId, "this.formBatch.levelId");
        studentBatchAddLevel({
          ...this.formBatch,
          studentLevelList: this.selectedRowKeys.map((item) => {
            return {
              list: item.list,
              studentId: item.id,
            };
          }),
          webStudentLevelListVO: this.studentList.filter(
            (item) => item.id == this.formBatch.levelId
          )[0],
        })
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("操作成功");
              this.loadingBatch = false;
              setTimeout(() => {
                this.resetForm();
              }, 500);
              this.onLoad();
              this.loadingBatch = false;
              this.visibleBatch = false;
              this.$refs.crud.toggleSelection();
            } else {
              // this.$message.error(res.msg);
              this.loadingBatch = false;
              done();
            }
          })
          .catch(() => (this.loadingBatch = false));
      }
    },
    //点击打开弹窗
    handleBatch(name) {
      if (!this.selectedRowKeys.length) {
        this.$message.error("请勾选");
        return;
      }
      this.batchName = name;
      this.visibleBatch = true;
    },
    //表格左侧选择的数据的
    selectionChange(list) {
      this.selectedRowKeys = list;
      console.log(list, "list1111111111");
      // console.log('选中的数据',list);
      // this.$message.success('选中的数据'+ JSON.stringify(list));
    },
  },
};
</script>

<style scoped lang="scss"></style>
