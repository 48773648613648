import { get, put, Delete, post } from "../axios";

// 学生列表
export const studentStudentPage = (params) =>
  get(`/web/student/student/studentPage`, params);

// 学生新增
export const studentAddStudent = (params) =>
  post(`/web/student/student/addStudent`, params);

// 学生编辑
export const studentUpdateStudent = (params) =>
  post(`/web/student/student/updateStudent`, params);

// 批量加入班级
export const studentBatchJoinClass = (params) =>
  post(`/web/student/student/batchJoinClass`, params);

// 批量添加等级
export const studentBatchAddLevel = (params) =>
  post(`/web/student/student/batchAddLevel`, params);

// 导出模板
export const studentStudentExport = (params) =>
  post(`/web/student/student/studentExport`, params);

// 导入模板
export const studentImportStudent = (params) =>
  post(`/web/student/student/importStudent`, params);

// 联动学生等级-班级下拉
export const studentClassList = (params) =>
  get(`/web/student/classList`, params);

// 学生启用禁用
export const studentUpdateState = (params) =>
  put("/web/student/updateState", params);
